const prefixScript = function(src) {
	var js_script = document.createElement('script');
	js_script.type = "text/javascript";
	js_script.src = src;
	js_script.async = true;
	document.getElementsByTagName('head')[0].appendChild(js_script);
}



if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Modernizr.classlist) {
  prefixScript('//cdnjs.cloudflare.com/ajax/libs/classlist/1.2.201711092/classList.min.js');
}


if (!Modernizr.picture) {
  prefixScript('//cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js');
}




const carousel = document.querySelector('.main-carousel');
if (carousel) {
	let flkty = new Flickity(carousel, {
  	fade: true,
		pageDots: true,
		prevNextButtons: false,
		autoPlay: 9000,
		setGallerySize: true,
		contain: true,
		adaptiveHeight: false
	});

	flkty.on('staticClick', function(event, pointer, cellElement, cellIndex ) {
		flkty.next(true);
	});
}


const $imgs = document.querySelectorAll('img');
$imgs.forEach((el) => {
	el.addEventListener('contextmenu', (e) => {
		e.preventDefault();
	}, false);

	el.ondragstart = () => {
		return false;
	};
});



const isSciencePage = document.querySelector('body.science');
if (isSciencePage) {
	const $footnoteLinks = document.querySelectorAll('sup a');
	$footnoteLinks.forEach((el) => {
		el.addEventListener('click', (e) => {
			const hash = el.hash;
			const note = document.querySelector(hash);
			note.classList.add('is-highlighted');
			window.setTimeout(() => {
				note.classList.remove('is-highlighted');
			}, 3000);
		});
	});
}





const $bios = document.querySelectorAll('.has-bio');
$bios.forEach((el) => {
	const name = el.querySelector('.name');
	const clickedEl = el;
	name.addEventListener('click', () => {
		let items = 0;

		$bios.forEach((el, index, arr) => {
			items = items + 1;
			if (el.classList.contains('active')) {
				el.classList.remove('active');
			}
			if (items === arr.length) {
				window.setTimeout(() => {
					clickedEl.classList.add('active');
				}, 175);
			}
		});
	});
});


const $modalTriggers = document.querySelectorAll('.modal-trigger');
const $modalCloseBtns = document.querySelectorAll('.modal-close');


$modalTriggers.forEach((el) => {
	el.addEventListener('click', (e) => {
		e.preventDefault();
		const selectedModalId = '#modal' +  el.dataset.modal;
		const modal = document.querySelector(selectedModalId);
		document.querySelector(selectedModalId).classList.add('is-active');
		window.addEventListener('keyup', mapEscToClose);

		if (modal.querySelectorAll('a')) {
			modal.querySelectorAll('a').forEach((link) => {
				link.addEventListener('click', (e) => {
					document.querySelector('.modal.is-active .modal-close').click();
				});
			});
		}



	});
});


$modalCloseBtns.forEach((el) => {
	el.addEventListener('click', () => {
		el.closest('.modal').classList.remove('is-active');
		window.removeEventListener('keyup', mapEscToClose);
	});
});


function mapEscToClose(e) {
	if (e.key !== undefined && e.keyCode === 27) {
		document.querySelector('.modal.is-active .modal-close').click();
	}
}


document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});
